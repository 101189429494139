import React from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export default class suprise extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(220 * particleRatio),
      });
  };

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    this.makeShot(0.2, {
      spread: 60,
    });

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  _handlerFire = () => {
    this.fire();
    // document.getElementById("myBtn").innerHTML = "Bingo 🎉";
    document.getElementById("batch").classList.remove("-z-10");
    console.log("clicked");
  };
  get handlerFire() {
    return this._handlerFire;
  }
  set handlerFire(value) {
    this._handlerFire = value;
  }

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  render() {
    return (
      <div className="flex justify-center bg-primary content-center z-10">
        <div className="pt-24 h-screen text-left sm:text-center">
          <h1 className="text-gray-200 text-3xl pl-5 ">Hey there 👋,</h1>
          <p className="text-slate-100 pt-5 pl-5 text-lg mb-10 sm:mb-24 font-mono">
            You might be wondering what this{" "}
            <span className="bold text-2xl text-pink-500 ">urqt</span> is all
            about; simply read each letter to find out. If you get it, please
            click the button below.
          </p>

          <div className="pl-5">
            <button
              onClick={this.handlerFire}
              className="inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-slate-200 rounded-lg group bg-gradient-to-br from-red-200 via-red-300 to-yellow-200 group-hover:from-red-200 group-hover:via-red-300 group-hover:to-yellow-200 dark:text-white dark:hover:text-gray-900 focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400"
            >
              <span
                id="myBtn"
                className="text-lg px-5 py-2.5 transition-all ease-in duration-75 bg-primary rounded-md group-hover:bg-opacity-0"
              >
                Click Me
              </span>
            </button>
          </div>
          <ReactCanvasConfetti
            refConfetti={this.getInstance}
            style={canvasStyles}
          />
        </div>
        <div
          id="batch"
          className="absolute w-full h-full bg-primary items-center -z-10"
        >
          <div className="mx-auto pt-24 p-4 w-auto text-sm font-mono text-white bg-transparent rounded-lg">
            <div className="flex justify-between align-top mb-5">
              <p className="text-xl text-slate-200">I am just kidding 😂😂 </p>
              {/* adding button to close pop-up window */}
              <button
                onClick={() => {
                  document.getElementById("batch").classList.add("-z-10");
                  document.getElementById("myBtn").innerHTML =
                    "You got Rickrolled";
                }}
                className="text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
                </svg>
              </button>
            </div>
            <img src="https://res.cloudinary.com/amrohan/image/upload/v1642928094/Images/tbzmggzjnxb9uaymrx3u.gif" alt="You Got Rick rolled" width="100%" height="300"/>
          </div>
        </div>
      </div>
    );
  }
}
